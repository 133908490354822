var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "button-click-left", staticStyle: { float: "left" } },
          [
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "unlink-panels": "",
                "start-placeholder": "Ngày bắt đầu",
                "end-placeholder": "Ngày kết thúc",
                "value-format": "yyyy-MM-dd",
                format: "dd-MM-yyyy",
              },
              on: {
                change: function ($event) {
                  return _vm.searchHeaderMethod()
                },
              },
              model: {
                value: _vm.dataSearch.dateList,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "dateList", $$v)
                },
                expression: "dataSearch.dateList",
              },
            }),
            _c(
              "el-input",
              {
                staticClass: "button-left-class",
                staticStyle: { width: "300px" },
                attrs: { placeholder: "Nhập tiêu đề tìm kiếm", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "title", $$v)
                  },
                  expression: "dataSearch.title",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.searchHeaderMethod()
                    },
                  },
                  slot: "append",
                }),
              ],
              1
            ),
            _c(
              "el-input",
              {
                staticClass: "button-left-class",
                staticStyle: { width: "300px" },
                attrs: { placeholder: "Nhập nội dung tìm kiếm", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "content", $$v)
                  },
                  expression: "dataSearch.content",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.searchHeaderMethod()
                    },
                  },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "button-click row-data" },
          [
            _c(
              "el-button",
              {
                staticClass: "button-over",
                attrs: { type: "success", loading: _vm.loaddingButton },
                on: {
                  click: function ($event) {
                    return _vm.viewNotifyParentMany()
                  },
                },
              },
              [_vm._v("Duyệt đã đọc")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.responseList,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "cell-style": _vm.tableRowStyle,
                "element-loading-text": _vm.$tableLoading,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "50", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Thời gian", width: "150", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDateTime")(scope.row.createdDate)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: "Tiêu đề",
                  width: "300",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "Nội dung", align: "left" },
              }),
              _c("el-table-column", {
                attrs: { width: "200px", prop: "", label: "File đính kèm" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-upload", {
                          attrs: {
                            "file-list": scope.row.fileList,
                            "on-preview": _vm.downloadFile,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.viewNotifyParentOne(scope.row)
                              },
                            },
                          },
                          [_vm._v("Xem")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paging-click row-data" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.pageNumber,
              "page-sizes": _vm.$pageListDefaultNew,
              "page-size": _vm.maxPageItem,
              layout: _vm.$pageLayoutDefault,
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chi tiết thông báo",
            visible: _vm.showDetailDialog,
            width: "70%",
            "close-on-click-modal": false,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailDialog = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "font-family": "arial" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "17px", "font-family": "arial" } },
              [_vm._v("Tiêu đề")]
            ),
            _c("div", [_vm._v(_vm._s(_vm.rowDetailData.title))]),
          ]),
          _c("br"),
          _c(
            "div",
            { staticStyle: { "font-size": "17px", "font-family": "arial" } },
            [_vm._v("Nội dung")]
          ),
          _c("el-input", {
            staticStyle: { "background-color": "#f0f0f0" },
            attrs: { type: "textarea", rows: 13 },
            model: {
              value: _vm.rowDetailData.content,
              callback: function ($$v) {
                _vm.$set(_vm.rowDetailData, "content", $$v)
              },
              expression: "rowDetailData.content",
            },
          }),
          _c("br"),
          _c("br"),
          _c(
            "div",
            { staticStyle: { "font-size": "17px", "font-family": "arial" } },
            [_vm._v("File đính kèm")]
          ),
          _c("br"),
          _c(
            "div",
            [
              _c("el-upload", {
                attrs: {
                  "file-list": _vm.rowDetailData.fileList,
                  "on-preview": _vm.downloadFile,
                },
              }),
            ],
            1
          ),
          _c("br"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }